import React from 'react'
import NonExpansiblePanel from '../../mui-components/NonExpansiblePanel/NonExpansiblePanel'
import SectionsChooser from '../sections-chooser'

const TypesOfSanctionsPage = props => {
  const { title, description, typeSections } = props

  return (
    <>
      {/* Static intro */}
      <NonExpansiblePanel title={title} highlight="true">
        {description}
      </NonExpansiblePanel>

      {/* Dynamic sections */}
      <SectionsChooser sections={typeSections} location={props.location} />
    </>
  )
}

export default TypesOfSanctionsPage
